import { Responsive, ResponsiveSection } from '../../../styles.interface';
import {config} from '@/product/config.json';

const PrivacyInfo: ResponsiveSection = {
  TermTitleParagraph: {
    xs: {
      'font-size': '13px',
      'font-weight': '400',
      'line-height': '20px',
      color: '#C3CCCC',
    },
  },
  ExpandParagraph: {
    xs: {
      color: '#D4FFFF',
      'font-size': '12px',
      'font-family': config.STYLEGUIDE.fontFamily.title,
    },
  },
};

const Register: ResponsiveSection = {
  CreditCardBox: {
    xs: {
      // background: config.STYLEGUIDE.colors.lighter,
      // 'border-color': config.STYLEGUIDE.colors.lighter,
    },
  },
  RegisterLink: {
    xs: {
      color: config.STYLEGUIDE.others.textColor,
    },
  },
  Price: {
    xs: {
      color: '#64DFDF',
    },
  },
};

const Header: ResponsiveSection = {
  BackdropMenuSmallLink: {
    xs: {
      'font-size': '16px',
      'line-height': '22px',
      color: '#B281FF',
    },
  },
  BackdropMenuCoinsLink: {
    xs: {
      color: '#D4FFFF',
    },
  },
};

const SubscriptionForm: ResponsiveSection = {
  LinkStyled: {
    xs: {
      color: config.STYLEGUIDE.colors.info,
    },
  },
};

const MembershipSection: ResponsiveSection = {
  CoinsDataBox: {
    xs: {
      background: config.STYLEGUIDE.colors.lighter,
      border: `1px solid #3B2B54`,
    },
  },
  ButtonStyled: {
    xs: {
      background: config.STYLEGUIDE.colors.error,
    },
  },
  ButtonStyledHover: {
    xs: {
      background: config.STYLEGUIDE.colors.info,
    },
  },
};

const OldPasswordForm: ResponsiveSection = {
  ButtonStyled: {
    xs: {
      background: config.STYLEGUIDE.colors.brand,
    },
  },
  ButtonStyledHover: {
    xs: {
      background: config.STYLEGUIDE.colors.infoHover,
    },
  },
};

const Modal: ResponsiveSection = {
  CoinsBox: {
    xs: {
      background: config.STYLEGUIDE.colors.lighter,
      border: '1px solid #3B2B54',
    },
  },
};

const MessageForm: ResponsiveSection = {
  ButtonStyled: {
    xs: {
      background: config.STYLEGUIDE.colors.brand,
    },
  },
  ButtonStyledHover: {
    xs: {
      background: config.STYLEGUIDE.colors.infoHover,
    },
  },
};

const Sections: Responsive = {
  Header,
  MessageForm,
  PrivacyInfo,
  Register,
  SubscriptionForm,
  Modal,
  MembershipSection,
  OldPasswordForm,
};

export default Sections;
